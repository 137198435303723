.Profile-content {
    text-align: center;
}

.Profile h1 {
    font-size: 2em;
}

.Profile h2 {
    font-size: 1.4em;
    color: rgb(136, 136, 136);
}

.Profile h3 {
    color: #006bb4;
    font-size: 1.2em;
}

.Profile-avatar {
    border-radius: 50%;
    height: 128px;
    width: auto;
    margin: auto;
}