.App-title {
  padding-top: 32px;
}

button:focus {
  outline: 0;
  outline: 0;
}

.center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

.euiBody--headerIsFixed .euiFlyout, 
.euiBody--headerIsFixed .euiCollapsibleNav {
    top: 49px;
    height: calc(100% - 49px);
}